import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PDP from "../components/nonprofit-cycle/pdp"

const NonprofitCycle: React.FC = () => {
  return (
    <Layout bgColor="black" showCycleLogos>
      <SEO title="Nonprofit Cycle" />

      <div className="relative flex h-screen flex-col items-center justify-center">
        <PDP />
      </div>
    </Layout>
  )
}

export default NonprofitCycle
