import React, { useState } from "react"
import CrossIcon from "../../images/svg/cross.svg"
import AdaOg from "../../images/cycle/ada_og.gif"
import AdaCycle from "../../images/cycle/ada_cycle.gif"

const PDP: React.FC = () => {
  const [showDetails, setShowDetails] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)

  const handleToggleDetails = () => {
    setShowDetails(!showDetails)
    setShowDisclaimer(false)
  }
  const handleToggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer)
    setShowDetails(false)
  }

  return (
    <section className="w-full">
      <div className="relative flex w-full flex-row justify-center">
        <img src={AdaCycle} className="max-h-full max-w-[35%] flex-1" />
        <img src={AdaOg} className="max-h-full max-w-[35%] flex-1" />
      </div>
      <h1 className="absolute top-0 left-1/2 mt-[66px] flex -translate-x-1/2 flex-col items-center justify-center text-center font-gravity-wide text-[24px] font-black uppercase leading-[1.2] md:text-[40px] lg:text-[2.75vw]">
        <div>Bridges for Music X OREMOB Drop</div>
      </h1>
      <div className="absolute bottom-4 left-1/2 flex w-11/12 -translate-x-1/2 flex-col gap-4 text-center font-gravity-wide text-[12px] uppercase sm:w-auto sm:min-w-[600px] lg:bottom-[2vh] lg:gap-[1.5vh] lg:text-[14px]">
        <div className="grid grid-cols-1 gap-4 text-left font-sans text-[12px] font-normal normal-case md:grid-cols-2 lg:gap-6 lg:text-[14px]">
          <div className="flex w-full items-end [grid-area:1/1] md:[grid-area:auto]">
            <div
              className={`pointer-events-auto relative flex w-full flex-col gap-[16px] rounded-[10px] bg-white/50 p-4 pr-8 backdrop-blur-lg transition-opacity duration-150 ease-in-out ${
                showDetails ? "opacity-100" : "pointer-events-none opacity-0"
              }`}
            >
              <button
                className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
                onClick={handleToggleDetails}
              >
                <CrossIcon className="h-4 w-4 text-white" />
              </button>
              <div className="space-y-[8px]">
                <h3 className="font-gravity-wide text-[14px] font-black uppercase leading-tight">
                  1 x CYCLE CASEY X OREMOB (DIGITAL)
                </h3>
                <div className="leading-tight">
                  Utility:
                  <ol className="list-disc pl-4">
                    <li>
                      Eligible Vouchers to PARTICIPATE IN A WL RAFFLE of the
                      “BRIDGES FOR MUSIC” OG Artwork, by Ore Ore Ore
                    </li>
                    <li>
                      Eligible Vouchers to participate in raffle for 2 x
                      Additional OREMOB 10k Pieces from the Team Vault
                    </li>
                    <li>
                      Eligible Vouchers to participate in raffle for 2 x Charm
                      Ring NFT
                    </li>
                    <li>
                      Eligible Vouchers to participate in raffle for 3 x OREMOB
                      Dad Hats including shipping
                    </li>
                    <li>
                      Eligible Voucher to participate in raffle for 10 x Sticker
                      Packs
                    </li>
                    <li>Secret Utility revealed post mint</li>
                    <li>
                      Person that purchases highest number of vouchers receives
                      a “ROW” handpicked OREMOB PFP
                    </li>
                  </ol>
                </div>
              </div>
              <div className="space-y-[8px]">
                <div className="leading-tight">
                  Utility:
                  <div>
                    Check out{" "}
                    <a
                      href="https://www.bridgesformusic.org"
                      target="_blank"
                      className="text-yellow"
                      rel="noreferrer"
                    >
                      www.bridgesformusic.org
                    </a>{" "}
                    and this charity cycle{" "}
                    <a
                      href="https://donate.bridgesformusic.org/home_page"
                      target="_blank"
                      rel="noreferrer"
                      className="text-yellow"
                    >
                      https://donate.bridgesformusic.org/home_page
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full items-end [grid-area:1/1] md:[grid-area:auto]">
            <div
              className={`pointer-events-auto relative flex w-full flex-col gap-[16px] rounded-[10px] bg-white/50 p-4 pr-8 backdrop-blur-lg transition-opacity duration-150 ease-in-out ${
                showDisclaimer ? "opacity-100" : "pointer-events-none opacity-0"
              }`}
            >
              <button
                className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
                onClick={handleToggleDisclaimer}
              >
                <CrossIcon className="h-4 w-4 text-white" />
              </button>
              <h3 className="font-gravity-wide text-[1.2em] font-black uppercase leading-none tracking-wide">
                Disclaimer:
              </h3>
              <ol className="list-disc pl-4 leading-tight">
                <li>
                  No KYC required. Winners can open a ticket to have pieces
                  shipped via Discord
                </li>
                <li>Multiple Vouchers = multiple chances in the raffle draw</li>
                <li>Announcement of final draw: 24th Oct. 2023</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 lg:gap-6">
          <button
            className={`flex w-[160px] items-center justify-center justify-self-end rounded-full py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:bg-yellow hover:text-black focus:text-black lg:w-[220px] ${
              showDetails ? "bg-yellow" : "bg-white"
            }`}
            onClick={handleToggleDetails}
          >
            Details
          </button>
          <button
            className={`flex w-[160px] items-center justify-center rounded-full py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:bg-yellow hover:text-black focus:text-black lg:w-[220px] ${
              showDisclaimer ? "bg-yellow" : "bg-white"
            }`}
            onClick={handleToggleDisclaimer}
          >
            Disclaimer
          </button>
        </div>
        <div className="flex flex-col leading-none">
          <span className="text-[0.9em]">DONATE:</span>
          <span className="text-[1.3em]">111 ADA</span>
        </div>
        <div className="flex justify-center">
          <div>
            <div>CYCLE CASEY</div>
            <button className="pointer-events-none flex w-[160px] items-center justify-center rounded-full bg-yellow/50 py-2 px-4 text-[12px] uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:text-black focus:text-black lg:w-[220px]">
              SOLD OUT
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PDP
